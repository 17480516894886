export const Banners = [
  {
    image: '/images/home/banner3.png',
    text: 'You Deserve To Eat Fresh Food',
  },
  {
    image: '/images/home/banner4.png',
    text: "It's Time For Good Breakfast",
  },
  {
    image: '/images/home/banner3.png',
    text: `Food Is Fuel Not Therapy `,
  },
];
